import React from "react";
import MainLayout from "layouts/MainLayout"

const Artist = () => {
  return (
    <MainLayout>
        <div/>
    </MainLayout>
  );
}

export default Artist;
